body {
  /*font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;*/
  font-family: "brandon grotesque", brandon, helvetica, sans-serif;
  overflow-x: hidden;
  //background-color: #fdfdfd;
  background-color: #f9f9f9;
  color: #1c1d1d;
  padding: 0;
  font-size: 1.2rem;
}

.container {
  max-width: 1400px;
}

.disabled {
  display: none;
}

/*
** FOOTER
 */
footer {
  color: #fff;
  padding-top: 80px;
}

footer h3 {
  margin-bottom: 30px
}

footer .footer-above {
  padding-top: 50px;
  background: #242529;
}

footer .footer-col {
  margin-bottom: 50px
}

footer .footer-below {
  padding: 25px 0;
  background: #242529;
}

/**
** HEADER V1
 */

.header-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 944px;
  overflow: hidden;
  background: url(../assets/img/background/overlay.png) repeat left top !important;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  font-family: "brandon grotesque", brandon, helvetica, sans-serif;
}

header.masthead {
  text-align: center;
  color: #fff;
  height: 944px;
  background-color: #eee;
  overflow: hidden;
}

.header-title div p {
  margin: 0;
}

.header-title-value {
  margin-top: 5px;
  font-size: 100px;
  font-weight: bold;
  transition: font-size 0.5s ease-out;
  line-height: 1.2;
}

.header-title-info {
  margin-top: 15px;
  font-size: 42px;
  font-weight: bold;
  transition: font-size 0.5s ease-out;
}

.header-title-head {
  display: none;
  font-style: italic;
  font-size: 40px;
  transition: font-size 0.5s ease-out;
}

@media (max-width: 720px) {
  .header-title-value {
    font-size: 80px;
  }
  .header-title-info {
    font-size: 35px;
  }
  .header-title-head {
    display: block;
    font-size: 40px;
  }
}

@media (max-width: 540px) {
  .header-title-value {
    font-size: 70px;
  }
  .header-title-info {
    font-size: 27px;
  }
  .header-title-head {
    font-size: 30px;
  }
}

@media (max-width: 470px) {
  .header-title-value {
    font-size: 60px;
  }
  .header-title-info {
    font-size: 25px;
  }
  .header-title-head {
    font-size: 25px;
  }
}

/*
** HEADER V2
 */
header.masthead2 {
  text-align: center;
  color: #fff;
  height: 430px;
  background-color: #eee;
  overflow: hidden;
}

header.masthead2 .header-container {
  height: 430px !important;
  background: url(../assets/img/background/overlay.png) repeat left top !important;
}

header.masthead2 .cb-slideshow li span {
  height: 430px !important;
}

/*
** ARROW DOWN ANIMATE
 */
.drop-arrow {
  position: absolute;
  bottom: 1px;
  left: 50%;
  width: 64px;
  height: 60px;
  overflow: hidden;
  opacity: 0.6;
  transform: translate(-50%, 0%);
}

.drop-arrow.disabled {
  display: none;
}

.drop-arrow:hover {
  opacity: 1;
  cursor: pointer;
}

.drop-arrow:hover span {
  //V1 border-image: linear-gradient(45deg, rgba(6, 168, 255, 0), rgba(6, 168, 255, 0.5), rgba(6, 168, 255, 1), rgba(6, 168, 255, 0.5), rgba(6, 168, 255, 0));
  border-image: linear-gradient(45deg, rgba(6, 168, 255, 0), rgba(6, 168, 255, 0.5), rgb(5, 146, 221), rgba(6, 168, 255, 0.5), rgba(6, 168, 255, 0));
  border-image-slice: 1;
}

.drop-arrow span {
  display: block;
  width: 45px;
  height: 45px;
  border-bottom: 5px solid;
  border-right: 5px solid;
  //V1 border-image: linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(6, 168, 255, 0.9), rgba(0, 0, 0, 0));
  border-image: linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(5, 147, 221, 0.9), rgba(0, 0, 0, 0));
  border-image-slice: 1;
  animation: animate 2s infinite;
  position: absolute;
  margin-left: 8px;
  top: -25px;
}

.drop-arrow.light span {
  border-image: linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(6, 168, 255, 0.9), rgba(0, 0, 0, 0));
}

.drop-arrow.light:hover span {
  border-image: linear-gradient(45deg, rgba(6, 168, 255, 0), rgba(6, 168, 255, 0.5), rgba(6, 168, 255, 1), rgba(6, 168, 255, 0.5), rgba(6, 168, 255, 0));
}

.drop-arrow span:nth-child(2) {
  animation-delay: -0.2s;
  top: -10px;
}

.drop-arrow span:nth-child(3) {
  animation-delay: -0.4s;
  top: 5px;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-30px, -30px);
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(30px, 30px);
  }
}

@media (max-width: 1400px) {
  .drop-arrow {
    display: none;
  }
}

/*
** SECTION
 */

section {
  padding-bottom: 40px;
  padding-top: 120px;
}

section#projects, section#skills {
  background-color: #eee;
}
section h2 {
  font-size: 3em;
  margin: 0
}

section.success {
  color: #fff;
  background: #51b2f9
}

@media (max-width: 767px) {
  section {
    padding-bottom: 20px;
    padding-top: 60px;
  }
}

/*
** SCROLL
 */
.scroll-top.disabled {
  display: none;
}

.scroll-top {
  position: fixed;
  z-index: 1049;
  right: 2%;
  bottom: 2%;
  width: 50px;
  height: 50px
}

.scroll-top .btn {
  font-size: 16px;
  line-height: 28px;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 100%
}

.scroll-top .btn:focus {
  outline: 0
}

/*
** NAVBAR
 */
.navbar.row {
  margin: 0;
}

.nav-title {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.nav-title :hover {
}

.navbar-nav {
  flex-direction: row;
  float: right;
  letter-spacing: 1px;
  padding-right: 2rem;
}

.navbar-brand {
  padding-left: 2rem;
  margin-right: 0;
}

.nav-item {
  margin-left: 7px;
}

div [data-nav=list] {
  margin-left: auto !important;
}

#mainNav {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
  font-size: 22px;
  text-transform: uppercase;
  border: none;
  background: #18191d;
  opacity: 0.8;
  transition: opacity 0.5s ease-out;
}

#mainNav a:focus {
  outline: 0
}

#mainNav .navbar-brand {
  color: #fff
}

#mainNav .navbar-brand.active, #mainNav .navbar-brand:active, #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
  color: #fff
}

#mainNav .navbar-nav li.nav-item a.nav-link {
  color: #fff
}

#mainNav .navbar-nav li.nav-item a.nav-link:hover {
  color: #71cef9;
  outline: 0
}

#mainNav .navbar-nav li.nav-item.active a.nav-link:hover {
  color: #fff;
  outline: 0
}

#mainNav .navbar-nav li.nav-item a.nav-link:active, #mainNav .navbar-nav li.nav-item a.nav-link:focus {
  color: #fff
}

#mainNav .navbar-nav > li.nav-item.active {
  color: #fff !important;
  border-radius: 3px !important;
  background: #51b2f9 !important;
}

#mainNav .navbar-nav > li.nav-item.active:active, #mainNav .navbar-nav > li.nav-item.active:focus, #mainNav .navbar-nav > li.nav-item.active:hover {
  color: #fff !important;
  background: #51b2f9 !important;
}

.navbar-filmy {
  opacity: 0 !important;
}

.navbar-filmy:hover {
  filter: alpha(opacity=50);
  opacity: 0.8 !important;
  -webkit-transition: opacity 0.3s ease-in !important;
  -moz-transition: opacity 0.3s ease-in !important;
  -ms-transition: opacity 0.3s ease-in !important;
  -o-transition: opacity 0.3s ease-in !important;
  transition: opacity 0.3s ease-in !important;
}

.navbar-opaque {
  opacity: 1 !important;
}

.navbar-item-grow li {
  transition: font-size 0.3s ease;
  font-size: 22px !important;
  font-weight: 900 !important;
}

.navbar-item-shorten li {
  transition: font-size 0.3s ease;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.navbar-mobile-toggle {
  display: none;
  top: 5px;
  right: 15px;
  position: fixed;
  z-index: 100;
  border-radius: 5px;
  overflow: hidden;
  transition: opacity 0.5s ease-out;
  max-width: fit-content;
}

.navbar-mobile-toggle button {
  padding: 0 7.5px !important;
}

.navbar-mobile-toggle button div {
  height: 14px !important;
}


.navbar-mobile-toggle:hover {
  cursor: pointer;
}

.navbar-mobile-toggle.active {
  opacity: 0.9;
}

@media(max-width: 1100px) {
  .navbar-nav {
    padding-right: 0;
  }
}

@media(max-width: 992px) {
  #mainNav div.col-12 {
    padding-right: 0;
  }
  .navbar-brand {
    padding-left: 1rem;
  }
}

@media (max-width: 880px) {
  .navbar-brand {
    padding-left: 0;
  }
  .nav-item {
    margin-left: 0;
  }
  .navbar-mobile-toggle {
    display: block;
  }
  .navbar-nav {
    flex-direction: column;
    max-height: 0;
    float: left;
    overflow: hidden;
    transition: max-height 0.2s ease-in;
  }
  .navbar-nav.mobileMenuActive {
    max-height: 1000px;
    transition: max-height 0.5s ease-in;
  }
}

@media (min-width: 992px) {
  #mainNav {
    padding: 15px 0;
    -webkit-transition: padding .3s;
    -moz-transition: padding .3s;
    transition: padding .3s
  }

  #mainNav .navbar-brand {
    font-size: 22px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s
  }

  #mainNav.navbar-shrink {
    padding: 5px 0;
  }

  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.5em
  }
}

/*
** PROFILE
 */

#profile {
  padding-top: 0 !important;
}

#profile p {
  font-size: 22px;
}

#profile a {
  color: #2968a8;
}

#profile a:hover {
  color: #1d4976;
  text-decoration: none;
}

#profile .p-title {
  font-size: 40px !important;
  font-weight: 400;
  font-family: "Rockwell", serif;
}

.profile-content {
  margin-top: 95px;
  padding-right: 0;
  padding-left: 25px;
}

.profile-pic img {
  overflow: hidden;
  width: 100%;
  border-radius: 50px 50px 2px 2px;
  border: 4px white solid;
}

@media (min-width: 1600px) {
  .profile-pic {
    padding-left: 0;
    padding-right: 30px;
  }
}

@media (max-width: 1000px) {
  #profile p {
    font-size: 20px;
  }

  #profile .p-title {
    font-size: 35px !important;
    font-weight: 400;
  }
}

@media (max-width: 767px) {
  .profile-content {
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .profile-pic img {
    border: 2px white solid;
  }

}

@media (min-width: 540px) and (max-width: 767px) {
  #profile .btn-social-icon, #profile .popover-social button {
    display: list-item !important;
    list-style: none;
  }
}

@media (max-width: 767px) {
  #profile .social-icon {
    margin-top: 100px;
  }
}

@media (max-width: 410px) {
  #profile .social-icon {
    padding: 0;
  }
}

/*
** ALIGN
 */

.horizontal-align {
  width: 50%;
  margin: 0 auto;
}

.vertical-align {
  position: relative;
  top: 40%;
  -webkit-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
}

/*
** POSITION
 */

.v-align {
  display: flex;
  align-items: center;
}

.h-align {
  display: flex;
  justify-content: center;
}

.c-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/*
** SIZES
 */
.width-full {
  width: 100%;
}

.height-full {
  height: 100%;
}

/*
** HOVER
*/

.hvr-underline-from-center::before {
  background: #42A8D8;
  height: 2px;
  -webkit-transition-duration: .3s;
  transition-duration: 0.3s;
}

/*
** TITLE
 */

.page-title {
  font-weight: bold;
  font-size: 2.7rem;
  color: #2d313b;
  text-align: center;
}

.section-title {
  margin-bottom: 50px;
  color: #474D5D
}

.title-border {
  padding-bottom: 5px;
  border-bottom: 2px solid;
  border-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(104, 109, 180, 0.6), rgba(0, 0, 0, 0));
  border-image-slice: 1;
}


.text-white .section-name {
  background-color: #f7f7f7;
  color: #535a6d;
}

.section-name {
  background-color: #535a6d;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  color: #f7f7f7;
  padding: 0px 10px;
  margin: 0px 15px;
}


/*
** SPINNER
 */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.fa-spinner {
  animation: spinner 2s linear infinite;
}

/*
** BUTTON
*/
.btn-link:hover {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  color: rgb(0, 41, 122) !important;
  background-color: rgba(130, 175, 214, 0.47) !important;
  border-color: rgba(106, 145, 180, 0.71) !important;
}

.btn-social-icon svg {
  margin: auto;
}

.btn-weixin {
  font-size: 1.1rem;
  background-color: #44b549;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff !important;
  margin: 5px 5px;
}

.btn-weixin:hover {
  background-color: #409b45;
}

.btn-weixin.btn-lg > :first-child {
  font-size: 1.95em;
}

.btn-messenger {
  font-size: 1.1rem;
  background-color: #009cff !important;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff !important;
  margin: 5px 5px;
}


.btn-messenger:hover {
  background-color: #0093e5 !important;
}

.btn-messenger.btn-lg > :first-child {
  font-size: 1.8em;
}

.btn-github {
  color: #fff !important;
  margin: 5px 5px;
}

.btn-github:hover {
  color: #fff !important;
}

.btn-internet {
  margin: 5px 5px;
  color: #2291e5 !important;
  border-color: rgb(60, 175, 238);
  background-color: #fff !important;
}

.btn-internet:hover {
  color: #1b72b4 !important;
  border-color: rgb(52, 151, 206);
}

.btn-file-word {
  margin: 5px 5px;
  color: #2968a8 !important;
  border-color: #307cc7;
  background-color: #fff !important;
}

.btn-file-word:hover {
  color: #215488 !important;
  border-color: #2968a8;
}

.btn-file-pdf {
  margin: 5px 5px;
  color: #da3535 !important;
  border-color: #d45050;
  background-color: #fff !important;
}

.btn-file-pdf:hover {
  color: #cb2424 !important;
  border-color: #da4040;
}

.btn-github.btn-lg > :first-child {
  font-size: 1.7em;
}

.btn-linkedin {
  color: #fff !important;
  margin: 5px 5px;
}

.btn-linkedin:hover {
  color: #fff !important;
}

.btn-linkedin.btn-lg > :first-child {
  font-size: 1.6em;
}


/*
** ICON
 */

.text-icon {
  margin-left: 0.8rem;
  color: rgba(66, 73, 131, 0.8);
}

/*
** FLOATING LABEL
 */

.floating-label-form-group {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 1px solid #d8d5d5
}

.floating-label-form-group input, .floating-label-form-group textarea {
  font-size: 1.5em;
  position: relative;
  z-index: 1;
  padding-right: 0;
  padding-left: 0;
  resize: none;
  border: none;
  border-radius: 3px;
  background: 0 0;
  box-shadow: none !important;
  text-indent: 10px;
}

.floating-label-form-group label {
  font-size: .85em;
  line-height: 1.764705882em;
  position: relative;
  z-index: 0;
  top: 2em;
  display: block;
  margin: 0;
  -webkit-transition: top .3s ease, opacity .3s ease;
  -moz-transition: top .3s ease, opacity .3s ease;
  -ms-transition: top .3s ease, opacity .3s ease;
  transition: top .3s ease, opacity .3s ease;
  vertical-align: middle;
  vertical-align: baseline;
  opacity: 0
}

.floating-label-form-group:not(:first-child) {
  padding-left: 14px;
  border-left: 1px solid #eee
}

.floating-label-form-group-with-value label {
  top: 0;
  opacity: 1
}

.floating-label-form-group-with-focus label {
  color: #51b2f9
}

form .row:first-child .floating-label-form-group {
  border-top: 1px solid #eee
}

/*
** BOOTSTRAP BTN
 */

.btn-primary {
  font-weight: 700;
  color: #fff;
  border-color: #2c3e50;
  background-color: #2c3e50;
}


.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  border-color: #000;
  background-color: #1a252f
}

.btn-primary:hover {
  color: #fff;
  border-color: #161f29;
  background-color: #1a252f
}

.btn-primary.active, .btn-primary:active, .open > .btn-primary.dropdown-toggle {
  color: #fff;
  border-color: #161f29;
  background-color: #1a252f
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .btn-primary.dropdown-toggle.focus, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle:hover {
  color: #fff;
  border-color: #000;
  background-color: #0d1318
}

.btn-primary.active, .btn-primary:active, .open > .btn-primary.dropdown-toggle {
  background-image: none
}

.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled].focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
  border-color: #2c3e50;
  background-color: #2c3e50
}

.btn-primary .badge {
  color: #2c3e50;
  background-color: #fff
}

/*
** Anchor
 */
a:hover {
  color: #167495;
  text-decoration: underline;
}

a {
  color: #20a8d8;
  text-decoration: none;
  background-color: transparent;
}

/*
** TREE LINE
 */

.tl-hidden-msg {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2.5rem;
}

.tl-item {
}

.tl-item-content:hover {
  /*background-color: rgba(43, 43, 43, 0.16);*/
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(110, 110, 110, 0.08), rgba(110, 110, 110, 0.17));
  border-radius: 4px;
  cursor: pointer;
}

.tl-item-content.active:hover {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(110, 110, 110, 0.17));
}

.tl-item-content {
  display: table-cell;
  vertical-align: top;
  padding-left: 10px;
  width: 100%;
}

.tl-item-content h3 {
  color: #363c4b;
  font-weight: bold;
  font-size: 1.6rem;
}

.tl-item-content strong {
  color: #363c4b;
  font-size: 1.25rem;
}

.tl-item-marker {
  position: relative;
  display: table-cell;
  vertical-align: top;
  padding-right: 20px;
}

.tl-item-marker::after {
  content: "";
  width: 3px;
  background: rgba(85, 180, 208, 0.72);
  display: block;
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 6px;
}

.tl-item-marker::before {
  background: #464c5d;
  border: 3px solid transparent;
  border-radius: 100%;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  top: 10px;
  left: 0;
  width: 15px;
  transition: background .3s ease-in-out, border .3s ease-in-out;
}

.tl-item-hover {
  opacity: 0;
  display: block;
  margin: auto;
  color: rgb(82, 94, 112);
}

.tl-item:hover .tl-item-marker::before {
  background: transparent;
  border: 3px solid #656b8a;
}

.tl-item-content:hover .tl-item-hover {
  opacity: 0.7;
  transition: opacity 0.5s ease;
}

.tl-item-hover:hover {
  color: rgba(105, 121, 142, 0.8) !important;
}

.tl-item p {
  margin-bottom: 0;
}

.tl-item-header {
  margin-bottom: 1.3rem;
}

.tl-item-header img {
  height: 45px;
  margin-right: 10px;
  margin-top: 6px;
}

.tl-item-header h3 {
  margin-bottom: 0.7rem;
}

.tl-item-date {
  display: block;
  font-size: 0.9rem;
  color: rgba(48, 55, 59, 0.88);
}

.tl-item-body {
}

.tl-item-bhead {
}

.tl-item-bcontent {
  margin-top: 20px;
  margin-left: 10px;
}

.tl-item-footer {
  margin: 0;
  padding-top: 10px;
}

.tl-item-labels {
  padding: 0;
  font-size: 1.2rem;
  float: left;
}

.tl-item-labels span {
  margin-left: 3px;
}

.tl-item-link {
  padding-right: 50px;
  font-size: 0.8rem;
  color: #168bad;
  text-align: right;
}

.tl-item-link:hover {
  color: #155f7e;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 720px) {
  .tl-item-bcontent {
    margin: 0;
  }
  .tl-item-bcontent ul {
    padding-left: 35px;
  }
}

@media (max-width: 650px) {

  .tl-item-bcontent ul {
    padding-left: 30px;
  }
}

@media (max-width: 570px) {
  .tl-item-footer {
    padding-left: 0;
  }
  .tl-item-bcontent ul {
    padding-left: 20px;
  }
}

/*
** DELIM
 */
.delim-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.delim-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.delim-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.break-delim {
  margin: 0;
  border: 1px solid;
  border-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(71, 77, 93, 0.6), rgba(0, 0, 0, 0));
  border-image-slice: 1;
}

/*
** PARAGRAPH
 */

.plvl {
  margin-left: 2px;
  margin-bottom: 5px !important;
}

.plvl-1 {
  margin-top: 1.2rem;
}

.plvl-2 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.plvl-2 ~ .plvl-2 {
  margin-top: 0;
}

.p-br {
  margin-bottom: 20px !important;
}

.p-br-2 {
  margin-bottom: 35px !important;
}


/*
** LIST
 */

.ul-circle {
  list-style: circle;
}

.ul-opt-info:hover {
  cursor: help;
}

.ul-opt-info ul {
  max-height: 0;
  overflow: hidden;
  transition: none;
}

.ul-opt-info:hover ul, .ul-opt-info:focus ul, .ul-opt-info:active ul {
  max-height: 1000px;
  background-color: rgba(223, 223, 223, 0.7);
  transition: max-height 1s ease-out;
}

.ul-opt-info p::after {
  content: "more ...";
  padding-left: 0.7rem;
  font-size: 0.6rem;
  color: #3372ff;
}

.ul-opt-info:hover p::after {
  content: none;
}

.ul-limit-info:hover li {
  display: list-item;
}

.ul-limit-info li {
  display: none;
}

.ul-limit-info li:nth-child(-n+3) {
  display: list-item;
}

.ul-limit-info::after {
  content: "next ...";
  padding-left: 0.7rem;
  font-size: 0.6rem;
  color: #3372ff;
}

.ul-limit-info:hover::after {
  content: none;
}

/*
** HIDDEN
 */

.hidden-block {
  max-height: 0;
  overflow: hidden;
  transition: none;
}

.hidden-block.active {
  max-height: 10000px;
  transition: max-height 1s ease-out;
}

/*
** BADGE
 */
.badge-info {
  background-color: rgba(20, 135, 155, 0.55);
}

.badge-light {
  background-color: rgba(228, 229, 230, 0.8);
}

/*
** POPOVER
 */
.popover-social button {
  padding: 0;
  border: none !important;
  outline: none !important;
}

.popover-social button:focus,
.popover-social button:active:focus,
.popover-social button.active:focus,
.popover-social button.focus,
.popover-social button:active.focus,
.popover-social button.active.focus {
  outline: none !important;
  box-shadow: none !important;
}

.popover-social .popover {
  border: none !important;
  outline: none !important;
  background: none !important;
}

.popover-social .popover-inner {
}

.popover-social .popover-body {
  padding: 0;
}

/*
** DISPLAY
 */
.d-contents {
  display: contents;
}

/*
** FORM
 */
.floating-label-form-group {
  border-color: #b4b1b1;
}

/*
** LINK
 */
.anchor {
  display: block;
  height: 80px; /*same height as header*/
  margin-top: -80px; /*same height as header*/
  visibility: hidden;
}